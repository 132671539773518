<template>
  <div class="donut-light-indicator" :title="`Light direction: ${direction}`">
    <svg
      class="donut-chart"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke="#333"
        stroke-width="20"
      />
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="none"
        stroke="#fff"
        stroke-width="20"
        stroke-dasharray="62.83 188.49"
        :style="rotationStyle"
      />
    </svg>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  direction: {
    type: String,
    validator: (value) => ["top", "right", "bottom", "left"].includes(value),
    default: "top",
  },
});

const rotationStyle = computed(() => {
  const rotations = {
    top: "rotate(45deg)",
    right: "rotate(135deg)",
    bottom: "rotate(225deg)",
    left: "rotate(315deg)",
  };
  const direction = props.direction.toLowerCase();
  return `transform: ${rotations[direction]}; transform-origin: center;`;
});
</script>

<style scoped>
.donut-light-indicator {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform-origin: center;
  transform: rotate(225deg);
}

.donut-chart {
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: rotate(-90deg);
}

@media (prefers-reduced-motion: no-preference) {
  .donut-chart {
    animation: pulse 2s infinite alternate;
  }
}

@keyframes pulse {
  from {
    transform: rotate(-45deg) scale(1);
  }
  to {
    transform: rotate(-45deg) scale(1.1);
  }
}
</style>
